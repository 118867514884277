<template>
  <v-container class="tracking">
    <v-progress-circular v-if="Loding == true" :size="150" :width="7" color="#00cdbc" indeterminate>
    </v-progress-circular>
    <v-row v-if="Loding == false">
      <v-col cols="12" md="6" sm="12">
        <CardTemplate :titulo="data.userName">
          <div class="user ml-1 my-2">
            <p style="font-weight: 700">
              Creación : <span>{{ data.progressCreatedAt }}</span>
            </p>
          </div>
          <div>
            <div v-for="(product, index) of data.products" :key="index">
              <div class="product">
                <div class="quantity_product-name">
                  <span class="quantity" style="font-weight: 700">{{ product.quantity }}x</span>
                  <span class="product-name">{{ product.name }}</span>
                </div>
                <span class="price-subtotal" style="font-weight: 700">{{
                    product.pricePerUnit | currency
                }}</span>
              </div>
              <div class="addon" v-for="(addon, l) of product.addons" :key="l">
                <div class="addon-name" style="padding-left: 5%; display: flex">
                  <div class="punto"></div>
                  {{ addon.name }}
                  <span class="mx-1" style="font-weight: 300; font-size: 12px">
                    ({{ addon.quantity }}x )</span>
                </div>
                <div style="display: flex; justify-content: end">
                  {{ addon.productPrice | currency }}
                </div>
              </div>
              <div style="
                  display: flex;
                  margin-top: 10px;
                  font-weight: 400;
                  justify-content: end;
                ">
                Total del producto : {{ product.priceSubTotal | currency }}
              </div>
            </div>
            <v-divider class="mt-3"></v-divider>
            <div style="
                display: flex;
                margin-top: 10px;
                font-weight: 400;
                justify-content: end;
              ">
              Propina : {{ data.orderTip | currency }}
            </div>
            <div v-if="data.orderType == 'regular'" style="
                display: flex;
                margin-top: 10px;
                font-weight: 400;
                justify-content: end;
              ">
              Costo de envio : {{ data.deliveryCost | currency }}
            </div>
            <div style="
                display: flex;
                margin-top: 10px;
                font-weight: 700;
                justify-content: end;
              ">
              Total de la orden : {{ data.orderTotal | currency }}
            </div>
          </div>
        </CardTemplate>
      </v-col>
      <v-col cols="12" md="6">
        <CardTemplate titulo="Estado de la orden">
          <div class="status_order" :style="`background-color: ${state_response.color}`">
            <h2>{{ state_response.text }}</h2>
          </div>
          <v-btn v-if="['enRoute', 'onDelivery'].includes(data.progressCurrentStatus)" block dark color="#00cdbc"
            height="55px" class="mt-4" style="border-radius: 10px"
            :href="`https://route-ocho.firebaseapp.com/${codeOrder}`" target="_blanck">
            Ver Ruta
          </v-btn>
        </CardTemplate>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import CardTemplate from "@/components/cardTemplate";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "tracking.",

  data: () => ({
    data: {},
    Loding: true,
    codeOrder: null,
    state: [
      { name: "placed", text: "Colocada", color: "rgb(125, 188, 255)" },
      { name: "accepted", text: "Aceptada", color: "#00cdbc" },
      { name: "enRoute", text: "En ruta", color: "#ff5900" },
      { name: "onDelivery", text: "En camino", color: "orange" },
      { name: "delivered", text: "Entregada", color: "#00cdbc" },
      { name: "completed", text: "Completada", color: "#79dc7d" },
      { name: "canceled", text: "Cancelada", color: "rgb(255, 93, 93)" },
      {
        name: "pendingValidation",
        text: "Validación pendiente",
        color: "purple",
      },
      { name: "rejected", text: "Rechazada", color: "red" },
    ],
  }),
  components: {
    CardTemplate,
  },
  computed: {
    state_response() {
      var status = this.data.progressCurrentStatus;
      let estado = this.state.filter((e) => e.name == status);

      // console.debug(estado);
      return estado[0];
    },
  },
  methods: {
    ...mapActions(["Alert_", "addData_info"]),
    getInformation() {
      let _id = this.$route.params._id;

      axios.get(
          `${process.env.VUE_APP_EL_OCHO}/httpPaymentLinksOrderStatus?code=${_id}`
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.data = resp.data;
            // console.debug(resp.data);

            let info = {
              logo: resp.data.businessLogo,
              shortName: resp.data.businessName,
              qrThemeColor: "#ff5900",
              qrThemeColor: resp.data.qrThemeColor,
              qrBackgroundImage: resp.data.qrBackgroundImage,
              qrBackgroundType: resp.data.qrBackgroundType,
            };
            this.addData_info(info);
          }
        })
        .catch((error) => {
          // console.debug(error);
          this.Loding = true;
          // window.location = `https://pay.ocho.life/payment/checkout/${_id}`;
        })
        .finally(() => (this.Loding = false));
    },
  },
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("es-HN", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  mounted() {
    // console.debug(this.$route.params._id);
    this.codeOrder = this.$route.params._id;
    this.getInformation();
    setInterval(() => {
      this.getInformation();
    }, 20000);
  },
};
</script>
<style lang="scss">
.quantity_product-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .quantity {
    width: 40px;
  }

  .product-name {
    font-weight: 700;
    margin-left: 10px;
  }
}

.tracking {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .quantity {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background-color: #00cdbc;
    display: flex;

    justify-content: center;
    align-items: center;
    color: #fff;
  }
}

.addon {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .addon-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .punto {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: #ff5900;
    }
  }
}

.status_order {
  padding: 20px;
  border-radius: 1em;
  margin-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
</style>
